type PriceVariant = {
    price: number // Crossed price, if it not exists, it is sale price
    discountedPrice: number | null // sale price, if crossed price not exists, it is null
}

const DISCOUNT_PERCENT_THRESHOLD = 15

class PriceHelper {
    public getPrice = (product: any, refererStore: any = {}): PriceVariant[] => {
        let defaultPrice = product.price_variants

        if (!refererStore) {
            return defaultPrice
        }

        const glnStoreChannel = refererStore.gln
        const storeChannelPrice = product.channel_price_variants && product.channel_price_variants[glnStoreChannel]
        if (storeChannelPrice) {
            defaultPrice = storeChannelPrice
        }

        return defaultPrice
    }

    public getVariantsPrice = (prices: PriceVariant[]): number[] => prices?.map((item) => item.price)

    public getVariantsSalePrice = (prices: PriceVariant[]): number[] => prices?.map((item) => item.discountedPrice)

    public getLowestPrice = (prices: number[]): number => Math.min(...prices)

    public getHighestPrice = (prices: number[]): number => Math.max(...prices)

    public getPricesWithoutNull = (prices: number[]): number[] => prices.filter((price) => !!price)

    public isDiscountOverThreshold = (discountedPrice, price): boolean => {
        if (!discountedPrice || discountedPrice === 0) {
            return false
        }

        return (100 - ((discountedPrice / price) * 100)) > DISCOUNT_PERCENT_THRESHOLD
    }

    public isProductOnSale = (prices: PriceVariant[]): boolean => prices.some((price) => this.isDiscountOverThreshold(price.discountedPrice, price.price))

    public findLowestAndCheckPrices = (priceVariants) => {
        if (!priceVariants || !priceVariants.length) {
            return { lowestPrice: 0, hasMultiplePrices: false }
        }
        // Extract all prices and discounted prices
        const allPrices: number[] = priceVariants.reduce((acc, variant) => {
            if (variant.price !== null) acc.push(variant.price)
            if (variant.discountedPrice !== null) acc.push(variant.discountedPrice)
            return acc
        }, [])

        // Get unique prices
        const uniquePrices: number[] = [...new Set(allPrices)]

        // Find the lowest price
        const lowestPrice = Math.min(...uniquePrices)

        // Check if there are more than one unique prices
        const hasMultiplePrices = uniquePrices.length > 1

        return { lowestPrice, hasMultiplePrices }
    }
}

export default new PriceHelper()
